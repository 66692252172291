import * as React from "react"
import { Link } from "gatsby"
import b from '../staticAssets/logo.png'


// markup
const NotFoundPage = () => {
  return (
    <main className="h-screen w-vw flex items-center justify-center">
        <Link to="/">
          <img loading="lazy" className="h-20 w-20 animate-pulse" src={b}/>
        </Link>
    </main>
  )
}

export default NotFoundPage
